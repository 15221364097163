<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Paciente*</label>
      <SelectClient v-model="reg.client_id"></SelectClient>
      <app-small-form-errors :errors="errors.client_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Tipo de Archivo*</label>
      <SelectType v-model="reg.type_id"></SelectType>
      <app-small-form-errors :errors="errors.type_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Codigo*</label>
      <input class="form-control" type="text" maxlength="255" v-model="reg.code">
      <app-small-form-errors :errors="errors.code"></app-small-form-errors>
    </div>
    <app-button-submit :disabled="errors"></app-button-submit>
  </form>
</template>

<script>
import SelectClient from "../../store-module/clients/Select";
import SelectType from "./SelectType";
import * as validate from "validate.js";
import { DentalClinicService } from "../service";

const formRules = {
  client_id: { presence: { message: "Requerido" } },
  type_id: { presence: { message: "Requerido" } },
  code: { presence: { message: "Requerido" } }
};

export default {
  components: {
    SelectClient,
    SelectType,
  },
  data: () => ({
    reg: {}
  }),
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  methods: {
    reset() {
      this.reg = {};
    },
    save() {
      DentalClinicService.saveClientFile(this.reg).then(() =>
        this.$emit("submitted")
      );
    }
  }
};
</script>

<style>
</style>
